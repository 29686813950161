import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    SvgIcon,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { CdmDataContainerBasicInfo, CdmStorageConnection, CdmStorageDetails } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import React, { useState } from "react";
import { BsGearFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ActionConfig, ActionMenuButton } from "../../../common/actions/CommonActions";
import { SelectableBox } from "../../../common/card/SelectableCard";
import { BasicReactTable } from "../../../common/table/BasicReactTable";
import { QueryTable } from "../../../common/table/QueryTable";
import { TabConfig, TabGroup } from "../../../common/tabs/TabComponents";
import { formatKnownDataType, formatTitleCase, KnownDataType } from "../../../common/utils/formatter";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { QueryResultWrapper } from "../../core/data/QueryResultWrapper";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { DialogState, useDialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { useGlobalDialogState } from "../../core/dialog/GlobalDialogState";
import { LabelChip } from "../../labels/labelCommon";
import { SelectLabelsForm } from "../../labels/SelectLabelsForm";
import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import {
    useAddCdmStorageLabel,
    useDeleteCdmStorage,
    useGetCdmStorageDetails,
    useListCdmDataAccessNodes,
    useListCdmStorageDataContainers,
    useRemoveCdmStorageLabel,
    useRescanCdmStorage,
} from "../unstructured_data_hooks";
import { EditStorageDialog, getStorageConnectionProtocolLabel, StorageConnectionTypeChip } from "./ConnectedStorageCommon";
import { CdmDataContainerStatusChip, getDataContainerTypeLabel } from "../UnstructuredDataCommon";

interface ConnectedStorageDetailsScreenProps {
    projectId: string;
}

export const ConnectedStorageDetailsScreen: React.FC<ConnectedStorageDetailsScreenProps> = (p) => {
    const { storageId } = useParams();
    const { projectId } = p;
    const editStorageDialogState = useDialogState();
    const rescanStorage = useRescanCdmStorage();
    const deleteStorage = useDeleteCdmStorage();
    const globalDialogState = useGlobalDialogState();

    const storageDetails = useGetCdmStorageDetails(projectId, storageId);
    const storageActions: ActionConfig[] = [
        {
            id: "edit",
            name: "Edit Storage",
            action: () => {
                editStorageDialogState.open();
            },
        },
        {
            id: "rescan",
            name: "Rescan Storage",
            action: async () => {
                await rescanStorage.mutateAsync({
                    projectId: projectId,
                    storageId: storageId,
                });
            },
        },
        {
            id: "delete",
            name: "Delete Storage",
            action: async () => {
                const confirmed = await globalDialogState.addConfirmDialog({
                    title: "Delete Storage",
                    message: "Are you sure you want to delete this storage?",
                });
                if (confirmed) {
                    await deleteStorage.mutateAsync({
                        projectId: projectId,
                        storageId: storageId,
                    });
                }
            },
        },
    ];

    const tabs: TabConfig[] = [
        {
            label: "Connections",
            renderer: () => {
                return <ConnectionsTab storage={storageDetails.data?.cdmStorage} />;
            },
        },
        {
            label: "Data Containers",
            renderer: () => {
                return <DataContainersTab storage={storageDetails.data?.cdmStorage} projectId={projectId} />;
            },
        },
        {
            label: "Info",
            renderer: () => {
                return <InfoTab storage={storageDetails.data?.cdmStorage} projectId={projectId} />;
            },
        },
    ];

    return (
        <ScreenContainer>
            <QueryResultWrapper queryResult={storageDetails}>
                <ScreenTitleBar
                    title={storageDetails.data?.cdmStorage.info.name}
                    actions={
                        <OperatorView>
                            <ActionMenuButton
                                buttonProps={{
                                    variant: "contained",
                                    color: "secondary",
                                    endIcon: (
                                        <SvgIcon>
                                            <BsGearFill />
                                        </SvgIcon>
                                    ),
                                }}
                                buttonLabel={"Storage Actions"}
                                actions={storageActions}
                            />
                        </OperatorView>
                    }
                />
                <TabGroup configs={tabs} border borderColor={"black"} />

                {editStorageDialogState.isOpen && (
                    <EditStorageDialog projectId={projectId} storage={storageDetails.data?.cdmStorage} dialogState={editStorageDialogState} />
                )}
            </QueryResultWrapper>
        </ScreenContainer>
    );
};

interface ConnectionsTabProps {
    storage: CdmStorageDetails.AsObject;
}

const ConnectionsTab: React.FC<ConnectionsTabProps> = (p) => {
    const { storage } = p;
    const [selectedProtocol, setSelectedProtocol] = useState<string>("");
    const [storageConnections, setStorageConnections] = useState<Array<CdmStorageConnection.AsObject>>(storage.connectionsList);

    const columnHelper = createColumnHelper<CdmStorageConnection.AsObject>();

    const columns = [
        columnHelper.accessor((r) => r, {
            id: "address",
            header: "Address",
            cell: (props) => {
                return `${props.cell.row.original.address}${props.cell.row.original.port ? `:${props.cell.row.original.port}` : ""}`;
            },
        }),
        columnHelper.accessor((r) => r.protocol, {
            id: "type",
            header: "Type",
            cell: (props) => {
                return <StorageConnectionTypeChip type={props.getValue()} />;
            },
        }),
        columnHelper.accessor((r) => r.nodeId, {
            id: "node",
            header: "Node ID",
            cell: (props) => props.getValue(),
        }),
        columnHelper.accessor((r) => r.createdAt, {
            id: "createdAt",
            header: "Created At",
            cell: (props) => {
                return <Typography>{formatKnownDataType(props.getValue(), KnownDataType.DATE)}</Typography>;
            },
        }),
        columnHelper.accessor((r) => r.modifiedAt, {
            id: "modifiedAt",
            header: "Modified At",
            cell: (props) => {
                return <Typography>{formatKnownDataType(props.getValue(), KnownDataType.DATE)}</Typography>;
            },
        }),
    ];

    return (
        <Box pt={2}>
            <Box mb={2}>
                <FormControl size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>Filter by Protocol</InputLabel>
                    <Select
                        value={selectedProtocol}
                        onChange={(e) => {
                            setSelectedProtocol(e.target.value);
                            if (e.target.value === "") {
                                setStorageConnections(storage.connectionsList);
                            } else {
                                setStorageConnections(storage.connectionsList.filter((c) => c.protocol === Number(e.target.value)));
                            }
                        }}
                        label={"Filter by Protocol"}
                    >
                        <MenuItem value="">All</MenuItem>
                        {Array.from(new Set(storage.connectionsList.map((conn) => conn.protocol))).map((protocol) => (
                            <MenuItem key={protocol} value={protocol}>
                                {getStorageConnectionProtocolLabel(protocol)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <BasicReactTable data={storageConnections} columns={columns} />
        </Box>
    );
};

interface DataContainersTabProps {
    storage: CdmStorageDetails.AsObject;
    projectId: string;
}

const DataContainersTab: React.FC<DataContainersTabProps> = (p) => {
    const { storage, projectId } = p;
    const nodeIdsList = storage.connectionsList.map((c) => c.nodeId);
    const dataAccessNodes = useListCdmDataAccessNodes(projectId, 0, 100);

    const [selectedNodeId, setSelectedNodeId] = useState<string>(nodeIdsList[0]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });
    const dataContainers = useListCdmStorageDataContainers({
        projectId,
        storageId: storage.info.id,
        nodeId: selectedNodeId,
        pageIndex,
        perPage: pageSize,
    });
    const columnHelper = createColumnHelper<CdmDataContainerBasicInfo.AsObject>();

    const columns = [
        columnHelper.accessor((r) => r.name, {
            id: "name",
            header: "Name",
        }),
        columnHelper.accessor((r) => r.type, {
            id: "type",
            header: "Type",
            cell: (props) => {
                return <Typography>{getDataContainerTypeLabel(props.getValue())}</Typography>;
            },
        }),
        columnHelper.accessor((r) => r.path, {
            id: "path",
            header: "Path",
        }),
        columnHelper.accessor((r) => r.sizeBytes, {
            id: "sizeBytes",
            header: "Size",
            cell: (props) => {
                return <Typography>{formatKnownDataType(props.getValue(), KnownDataType.CAPACITY)}</Typography>;
            },
        }),
        columnHelper.accessor((r) => r.status, {
            id: "status",
            header: "Status",
            cell: (props) => {
                return <CdmDataContainerStatusChip status={props.getValue()} />;
            },
        }),
    ];

    return (
        <Box pt={2}>
            <QueryResultWrapper queryResult={dataAccessNodes}>
                <Grid container spacing={2}>
                    {nodeIdsList.map((nodeId) => {
                        const node = dataAccessNodes.data?.itemsList.find((n) => n.deployment.systemId === nodeId);
                        const selected = nodeId === selectedNodeId;
                        return (
                            <Grid key={nodeId}>
                                <SelectableBox selected={selected} onSelect={() => setSelectedNodeId(nodeId)}>
                                    <Box p={1}>
                                        <Typography variant={"button"} color={selected ? "primary" : "text.secondary"}>
                                            {node?.deployment.systemName}
                                        </Typography>
                                    </Box>
                                </SelectableBox>
                            </Grid>
                        );
                    })}
                </Grid>
                <QueryTable
                    data={dataContainers.data?.itemsList}
                    columns={columns}
                    pagination={{
                        pageIndex,
                        pageSize,
                    }}
                    setPagination={setPagination}
                    pageCount={dataContainers.data?.pagerMeta.totalPages}
                    emptyTableConfig={{
                        title: "No data containers found",
                        message: "No data containers found for the selected node",
                    }}
                />
            </QueryResultWrapper>
        </Box>
    );
};

interface InfoTabProps {
    storage: CdmStorageDetails.AsObject;
    projectId: string;
}

const InfoTab: React.FC<InfoTabProps> = (p) => {
    const { storage, projectId } = p;
    const removeStorageLabel = useRemoveCdmStorageLabel();
    const handleRemoveStorageLabel = async (label: string) => {
        await removeStorageLabel.mutateAsync({
            projectId: projectId,
            storageId: storage.info.id,
            label: label,
        });
    };

    const addStorageLabelDialogState = useDialogState();

    return (
        <Box pt={2}>
            <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <Card>
                        <List>
                            <ListSubheader>{"Storage Info"}</ListSubheader>
                            <ListItem>
                                <ListItemText primary={"Name"} secondary={storage.info.name} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Vendor"} secondary={storage.info.vendor} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={"Description"} secondary={storage.info.description || "N/A"} />
                            </ListItem>
                            {storage.labelsList.length > 0 && (
                                <ListItem>
                                    <ListItemText
                                        primary={"Labels"}
                                        secondary={
                                            <Stack direction={"row"} spacing={1} mb={1}>
                                                {storage.labelsList.map((label) => (
                                                    <LabelChip
                                                        key={label.name}
                                                        label={label.name}
                                                        color={label.color}
                                                        onDelete={() => handleRemoveStorageLabel(label.name)}
                                                    />
                                                ))}
                                            </Stack>
                                        }
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Card>
                </Grid>
            </Grid>
            {addStorageLabelDialogState.isOpen && (
                <AddStorageLabelDialog projectId={projectId} storageId={storage.info.id} dialogState={addStorageLabelDialogState} />
            )}
        </Box>
    );
};

interface AddStorageLabelDialogProps {
    projectId: string;
    storageId: string;
    dialogState: DialogState;
}

const AddStorageLabelDialog: React.FC<AddStorageLabelDialogProps> = (p) => {
    const { projectId, storageId, dialogState } = p;
    const fullScreen = useShouldDialogFullScreen();
    const [labels, setLabels] = useState<Label.AsObject[]>([]);

    const addStorageLabel = useAddCdmStorageLabel();

    const handleAddStorageLabel = async (label: string) => {
        await addStorageLabel.mutateAsync({
            projectId: projectId,
            storageId: storageId,
            label: label,
        });
    };
    return (
        <Dialog open={dialogState.isOpen} onClose={dialogState.close} fullScreen={fullScreen} maxWidth={"md"} fullWidth>
            <DialogTopBar dialogState={dialogState} title={"Add Label"} />
            <DialogContent>
                <SelectLabelsForm
                    currentlySelectedLabels={labels}
                    onClickLabel={(label) => {
                        if (!!labels.find((l) => l.name === label.name)) {
                            setLabels(labels.filter((l) => l.name !== label.name));
                        } else {
                            setLabels([...labels, label]);
                        }
                    }}
                    projectId={projectId}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dialogState.close();
                    }}
                >
                    {`Cancel`}
                </Button>
                <Button
                    variant={"contained"}
                    onClick={async () => {
                        for (const label of labels) {
                            await handleAddStorageLabel(label.name);
                        }
                        dialogState.close();
                    }}
                >
                    {`Add Labels`}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
