import { AddCdmStorage } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { Label } from "gc-web-proto/galaxycompletepb/apipb/domainpb/labels_pb";
import { create } from "zustand";

interface ConnectNewStorageWizardState {
    resetState: () => void;
    selectedIntegration: number;
    selectedIntegrationModule: IntegrationModule;
    setSelectedIntegration: (id: number) => void;
    setSelectedIntegrationModule: (module: IntegrationModule) => void;
    customSetup: boolean;
    setCustomSetup: (customSetup: boolean) => void;
    connectionsList: Array<AddCdmStorage.Request.StorageConnection>;
    addConnection: (connection: AddCdmStorage.Request.StorageConnection) => void;
    removeConnection: (connectionAddress: string) => void;
    editConnection: (connection: AddCdmStorage.Request.StorageConnection) => void;
    storageName: string;
    setStorageName: (name: string) => void;
    vendor: string;
    setVendor: (vendor: string) => void;
    description: string;
    setDescription: (description: string) => void;
    labels: Label.AsObject[];
    setLabels: (labels: Label.AsObject[]) => void;
    makeCreateStorageRequest: (projectId: string) => AddCdmStorage.Request;
}

export const useConnectNewStorageWizardState = create<ConnectNewStorageWizardState>((set, get) => ({
    resetState: () => {
        set({
            selectedIntegration: null,
            selectedIntegrationModule: null,
            customSetup: null,
            connectionsList: [],
            storageName: "",
            vendor: "",
            description: "",
            labels: [],
        });
    },
    selectedIntegration: null,
    setSelectedIntegration: (id: number) => {
        set({
            selectedIntegration: id,
        });
    },
    selectedIntegrationModule: null,
    setSelectedIntegrationModule: (module: IntegrationModule) => {
        set({
            selectedIntegrationModule: module,
        });
    },
    customSetup: null,
    setCustomSetup: (customSetup: boolean) => {
        set({
            customSetup: customSetup,
        });
    },
    connectionsList: [],
    addConnection: (connection: AddCdmStorage.Request.StorageConnection) => {
        set({
            connectionsList: [...get().connectionsList, connection],
        });
    },
    removeConnection: (connectionAddress: string) => {
        set({
            connectionsList: get().connectionsList.filter((c) => c.getAddress() !== connectionAddress),
        });
    },
    editConnection: (connection: AddCdmStorage.Request.StorageConnection) => {
        set({
            connectionsList: get().connectionsList.map((c) => {
                if (c.getNfs() && connection.getNfs()) {
                    return connection;
                }
                if (c.getSmb() && connection.getSmb()) {
                    return connection;
                }
                if (c.getS3() && connection.getS3()) {
                    return connection;
                }
                return c;
            }),
        });
    },
    storageName: "",
    setStorageName: (name: string) => {
        set({
            storageName: name,
        });
    },
    vendor: "",
    setVendor: (vendor: string) => {
        set({
            vendor: vendor,
        });
    },
    description: "",
    setDescription: (description: string) => {
        set({
            description: description,
        });
    },
    labels: [],
    setLabels: (labels: Label.AsObject[]) => {
        set({
            labels: labels,
        });
    },
    makeCreateStorageRequest: (projectId: string) => {
        return new AddCdmStorage.Request()
            .setIntegrationId(get().selectedIntegration)
            .setProjectId(projectId)
            .setName(get().storageName)
            .setVendor(get().vendor)
            .setDescription(get().description)
            .setLabelsList(get().labels.map((l) => l.name))
            .setConnectionsList(get().connectionsList);
    },
}));
