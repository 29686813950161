import {
    CdmDataAccessNodeInfo,
    CdmStorageBasicInfo,
    CdmStorageConnection,
    CdmStorageConnectionOptions,
    CdmStorageListItem,
    CdmDataContainerBasicInfo,
    CdmMigrationBasicInfo,
    CdmMigrationPassBasicInfo,
    CdmMigrationProgressSummary,
    CdmMigrationPass,
    CdmMigrationDetailedInfo,
    CdmQosSchedule,
    CdmReSyncSchedule,
    CdmBandwidthLimitSchedule,
    CdmStorageIdentityInfo,
    CdmDataContainerIdentityInfo,
    CdmDataAccessNodeIdentityInfo,
    CdmMigrationsSummary,
    CdmMigrationInfo,
    CdmStorageDetails,
    CdmMigrationPassType,
    CdmSynchronizationStatus,
} from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { mockDeploymentInfo1, mockDeploymentInfo2, mockDeploymentInfo3 } from "./FixturesCommon";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { Duration } from "google-protobuf/google/protobuf/duration_pb";
import { DayOfWeek } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";

const mockCdmStorageConnection1 = new CdmStorageConnection()
    .setNodeId(mockDeploymentInfo1.getSystemId())
    .setStorageId("1")
    .setProtocol(CdmStorageConnection.Protocol.PROTOCOL_SMB)
    .setId("1")
    .setAddress("192.168.1.10")
    .setCreatedAt(new Timestamp().setSeconds(1625097600).setNanos(0))
    .setModifiedAt(new Timestamp().setSeconds(1625097700).setNanos(0));

const mockCdmStorageConnection2 = new CdmStorageConnection()
    .setNodeId(mockDeploymentInfo2.getSystemId())
    .setStorageId("1")
    .setStorageId("1")
    .setProtocol(CdmStorageConnection.Protocol.PROTOCOL_NFS)
    .setId("2")
    .setAddress("192.168.1.12")
    .setCreatedAt(new Timestamp().setSeconds(1625097600).setNanos(0))
    .setModifiedAt(new Timestamp().setSeconds(1625097700).setNanos(0));

const mockCdmStorageConnection3 = new CdmStorageConnection()
    .setNodeId(mockDeploymentInfo3.getSystemId())
    .setStorageId("1")
    .setProtocol(CdmStorageConnection.Protocol.PROTOCOL_S3)
    .setId("3")
    .setAddress("192.168.1.13")
    .setCreatedAt(new Timestamp().setSeconds(1625097600).setNanos(0))
    .setModifiedAt(new Timestamp().setSeconds(1625097700).setNanos(0));

export const mockCdmStorageListItem1 = new CdmStorageListItem()
    .setInfo(
        new CdmStorageBasicInfo()
            .setName("Connected Storage 1")
            .setProjectId("8df3c2a5-7ea3-4c89-9be1-162acef020be")
            .setDescription("Description here")
            .setId("1")
            .setVendor("Vendor")
    )
    .setConnectionsList([mockCdmStorageConnection1, mockCdmStorageConnection2, mockCdmStorageConnection3]);

export const mockCdmDataAccessNodeInfo1 = new CdmDataAccessNodeInfo()
    .setDeployment(mockDeploymentInfo1)
    .setCpu("CPU")
    .setMemory(343453543523)
    .setOsClass("ubuntu linux 20.04 (debian)")
    .setOsType("")
    .setKernelVersion("5.4.0-1024-aws")
    .setMemoryUsed(53543523)
    .setCpuUsagePercent(20);

export const mockCdmDataAccessNodeInfo2 = new CdmDataAccessNodeInfo()
    .setDeployment(mockDeploymentInfo2)
    .setCpu("CPU")
    .setMemory(343453543523)
    .setOsClass("ubuntu linux 20.04 (debian)")
    .setOsType("")
    .setKernelVersion("5.4.0-1024-aws")
    .setMemoryUsed(53543523)
    .setCpuUsagePercent(20);

export const mockCdmDataAccessNodeInfo3 = new CdmDataAccessNodeInfo()
    .setDeployment(mockDeploymentInfo3)
    .setCpu("CPU")
    .setMemory(343453543523)
    .setOsClass("ubuntu linux 20.04 (debian)")
    .setOsType("")
    .setKernelVersion("5.4.0-1024-aws")
    .setMemoryUsed(53543523)
    .setCpuUsagePercent(20);

export const mockCdmStorageDetails1 = new CdmStorageDetails()
    .setInfo(mockCdmStorageListItem1.getInfo())
    .setConnectionsList(mockCdmStorageListItem1.getConnectionsList());

export const mockCdmDataContainerBasicInfo1 = new CdmDataContainerBasicInfo()
    .setId("container1")
    .setName("Test Container")
    .setType(CdmDataContainerBasicInfo.ContainerType.CONTAINER_TYPE_BUCKET)
    .setPath("/test/path")
    .setSizeBytes(1024)
    .setStatus(CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_ONLINE);

export const mockCdmDataContainerBasicInfo2 = new CdmDataContainerBasicInfo()
    .setId("container2")
    .setName("Test Container 2")
    .setType(CdmDataContainerBasicInfo.ContainerType.CONTAINER_TYPE_CONTAINER)
    .setPath("/test/path2")
    .setSizeBytes(2048)
    .setStatus(CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_OFFLINE);

export const mockCdmDataContainerBasicInfo3 = new CdmDataContainerBasicInfo()
    .setId("container3")
    .setName("Test Container 3")
    .setType(CdmDataContainerBasicInfo.ContainerType.CONTAINER_TYPE_FOLDER)
    .setPath("/test/path3")
    .setSizeBytes(3072)
    .setStatus(CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_ERROR);

export const mockCdmStorageIdentityInfo1 = new CdmStorageIdentityInfo().setId("storage1").setName("Test Storage");

export const mockCdmDataContainerIdentityInfo1 = new CdmDataContainerIdentityInfo().setId("container1").setName("Test Container");

export const mockCdmDataAccessNodeIdentityInfo1 = new CdmDataAccessNodeIdentityInfo().setId("node1").setName("Test Node");

export const mockTimestamp1 = new Timestamp().setSeconds(1625097600).setNanos(0);

export const mockDuration1 = new Duration().setSeconds(3600).setNanos(0);

export const mockCdmBandwidthLimitSchedule1 = new CdmBandwidthLimitSchedule()
    .setStart(0)
    .setEnd(86400)
    .setDaysOfWeekList([DayOfWeek.MONDAY])
    .setBandwidthLimitMbps(100);

export const mockCdmReSyncSchedule1 = new CdmReSyncSchedule()
    .setFrequency(CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_DAILY)
    .setRunDaysList([DayOfWeek.MONDAY])
    .setStartAt(mockTimestamp1);

export const mockCdmQosSchedule1 = new CdmQosSchedule().setUseCustomBandwidthLimit(true).setBandwidthLimitSchedulesList([mockCdmBandwidthLimitSchedule1]);

export const mockCdmMigrationPassBasicInfo1 = new CdmMigrationPassBasicInfo()
    .setId("pass1")
    .setScheduledAt(mockTimestamp1)
    .setStartedAt(mockTimestamp1)
    .setEndedAt(mockTimestamp1)
    .setType(CdmMigrationPassType.TYPE_INITIAL)
    .setStatus(CdmSynchronizationStatus.SYNCHRONIZATION_STATUS_COMPLETED);

export const mockCdmMigrationProgressSummary1 = new CdmMigrationProgressSummary()
    .setTotalExistingCapacity(1024)
    .setTotalExistingObjects(100)
    .setTotalScanDuration(mockDuration1)
    .setTotalDataScanned(512)
    .setTotalObjectScanned(50)
    .setTotalScanErrors(0)
    .setCurrentScanRate(10)
    .setTotalSyncDuration(mockDuration1)
    .setTotalDataSynced(256)
    .setTotalDataSyncSkipped(0)
    .setTotalObjectSynced(25)
    .setTotalObjectSyncSkipped(0)
    .setTotalSyncErrors(0)
    .setTotalSyncSkipped(0)
    .setCurrentSyncRate(5)
    .setCurrentDataTransferRate(1);

export const mockCdmMigrationPass1 = new CdmMigrationPass().setInfo(mockCdmMigrationPassBasicInfo1).setStats(mockCdmMigrationProgressSummary1);

export const mockCdmMigrationBasicInfo1 = new CdmMigrationBasicInfo()
    .setId("migration1")
    .setStatus(CdmSynchronizationStatus.SYNCHRONIZATION_STATUS_RUNNING)
    .setTimezone("UTC")
    .setPhase(CdmMigrationBasicInfo.CdmMigrationPhase.PHASE_MIGRATION)
    .setCreatedAt(mockTimestamp1)
    .setModifiedAt(mockTimestamp1)
    .setStartedAt(mockTimestamp1)
    .setBasePath("/source/path")
    .setDestinationPath("/dest/path")
    .setProject(null) // Set appropriate project info if needed
    .setSourceStorage(mockCdmStorageIdentityInfo1)
    .setSourceDataContainer(mockCdmDataContainerIdentityInfo1)
    .setDestinationStorage(mockCdmStorageIdentityInfo1)
    .setDestinationDataContainer(mockCdmDataContainerIdentityInfo1)
    .setSourceDataAccessNode(mockCdmDataAccessNodeIdentityInfo1);

export const mockCdmMigrationDetailedInfo1 = new CdmMigrationDetailedInfo()
    .setMigration(mockCdmMigrationBasicInfo1)
    .setExcludedPatternsList(["*.tmp"])
    .setResyncSchedule(mockCdmReSyncSchedule1)
    .setQosSchedule(mockCdmQosSchedule1)
    .setTotalPasses(4)
    .setCompletedPasses(2)
    .setPassSummaryList([mockCdmMigrationPassBasicInfo1])
    .setLastCompletedPassDetails(mockCdmMigrationPass1)
    .setTotalDataTransferredBytes(256)
    .setTotalDataBytes(1024)
    .setProgress(25);

export const mockCdmMigrationInfo1 = new CdmMigrationInfo().setMigration(mockCdmMigrationBasicInfo1).setPassesList([mockCdmMigrationPassBasicInfo1]);

export const mockCdmMigrationsSummary1 = new CdmMigrationsSummary().setTotalSessions(10).setActiveSessions(5).setCompletedSessions(4).setTotalDataBytes(2048);
