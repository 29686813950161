import { CreateCdmMigration } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import { CdmReSyncSchedule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { DayOfWeek } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { create } from "zustand";

export interface CdmMigrationWizardState {
    sourceStorageId: string | null;
    sourceNodeId: string | null;
    sourceContainerId: string | null;
    sourceBasePath: string | null;
    destinationPath: string | null;
    destinationNodeId: string | null;
    destinationContainerId: string | null;
    destinationStorageId: string | null;
    timezone: string;
    setTimezone: (timezone: string) => void;
    setSourceStorageId: (storageId: string) => void;
    setSourceNodeId: (dataAccessNodeId: string) => void;
    setSourceContainerId: (dataContainerId: string) => void;
    setSourceBasePath: (basePath: string) => void;
    setDestinationStorageId: (storageId: string) => void;
    setDestinationNodeId: (dataAccessNodeId: string) => void;
    setDestinationContainerId: (dataContainerId: string) => void;
    setDestinationPath: (basePath: string) => void;
    reSyncFrequency: CdmReSyncSchedule.ReSyncFrequency;
    setReSyncFrequency: (frequency: CdmReSyncSchedule.ReSyncFrequency) => void;
    reSyncDays: DayOfWeek[];
    setReSyncDays: (days: DayOfWeek[]) => void;
    reSyncTime: Date;
    setReSyncTime: (time: Date) => void;
    createCdmMigrationRequest: () => CreateCdmMigration.Request;
    cronExpression: string;
    setCronExpression: (expression: string) => void;
}

export const useCdmMigrationWizardState = create<CdmMigrationWizardState>((set, get) => ({
    sourceStorageId: null,
    sourceNodeId: null,
    sourceContainerId: null,
    sourceBasePath: "/",
    destinationStorageId: null,
    destinationNodeId: null,
    destinationContainerId: null,
    destinationPath: "/",
    timezone: "",
    reSyncFrequency: CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_UNSPECIFIED,
    setReSyncFrequency: (frequency: CdmReSyncSchedule.ReSyncFrequency) => set({ reSyncFrequency: frequency }),
    reSyncDays: [],
    setReSyncDays: (days: DayOfWeek[]) => set({ reSyncDays: days }),
    reSyncTime: new Date(),
    cronExpression: "",
    setCronExpression: (expression: string) => set({ cronExpression: expression }),
    setReSyncTime: (time: Date) => set({ reSyncTime: time }),
    setTimezone: (timezone: string) => set({ timezone: timezone }),
    setSourceStorageId: (storageId: string) => set({ sourceStorageId: storageId }),
    setSourceNodeId: (dataAccessNodeId: string) => set({ sourceNodeId: dataAccessNodeId }),
    setSourceContainerId: (dataContainerId: string) => set({ sourceContainerId: dataContainerId }),
    setSourceBasePath: (basePath: string) => set({ sourceBasePath: basePath }),
    setDestinationStorageId: (storageId: string) => set({ destinationStorageId: storageId }),
    setDestinationNodeId: (dataAccessNodeId: string) => set({ destinationNodeId: dataAccessNodeId }),
    setDestinationContainerId: (dataContainerId: string) => set({ destinationNodeId: dataContainerId }),
    setDestinationPath: (basePath: string) => set({ destinationPath: basePath }),
    createCdmMigrationRequest: () => {
        const req = new CreateCdmMigration.Request()
            .setSourceNodeId(get().sourceNodeId)
            .setSourceStorageId(get().sourceStorageId)
            .setSourceContainerId(get().sourceContainerId)
            .setBasePath(get().sourceBasePath)
            .setDestinationNodeId(get().destinationNodeId)
            .setDestinationStorageId(get().destinationStorageId)
            .setDestinationContainerId(get().destinationContainerId)
            .setDestinationPath(get().destinationPath);
        return req;
    },
}));
