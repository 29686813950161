import { Box, InputLabel, FormControl, MenuItem, Select, Stack, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SelectableBox, SelectableCard } from "../../../../common/card/SelectableCard";
import { useCdmMigrationWizardState } from "./CdmMigrationWizardState";
import { useState } from "react";
import { MdOutlineCloud } from "react-icons/md";
import { ImTree } from "react-icons/im";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import { useListGalaxyMigrateLinks } from "../../../galaxymigrate/gm_deployment_hooks";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { CreateGalaxyMigrateLinkButton } from "../../../galaxymigrate/links/CreateGalaxyMigrateLinkForm";

interface CdmMigrationWizardDestinationConnectivityStepProps {
    projectId: string;
}

export const CdmMigrationWizardDestinationConnectivityStep: React.FC<CdmMigrationWizardDestinationConnectivityStepProps> = (p) => {
    const { projectId } = p;
    const wizardState = useCdmMigrationWizardState();
    const [remoteDestination, setRemoteDestination] = useState(null);

    const handleSelectSameDataAccessNode = () => {
        setRemoteDestination(false);
        wizardState.setDestinationNodeId(wizardState.sourceNodeId);
    };

    const handleSelectRemoteDataAccessNode = () => {
        setRemoteDestination(true);
        wizardState.setDestinationNodeId(null);
    };

    return (
        <Box>
            <Typography color={"textSecondary"}>{"Select the type of migration you want to perform."}</Typography>
            <Grid container spacing={2} mt={2} mb={2}>
                <Grid size={{ xs: 12, md: 4 }}>
                    <SelectableCard
                        selected={remoteDestination === false}
                        onSelect={handleSelectSameDataAccessNode}
                        cardProps={{ sx: { height: "100%" } }}
                        icon={<ImTree size={"6em"} />}
                        title={"Same Data Access Node"}
                        description={"Migrate data to the same data access node."}
                    />
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <SelectableCard
                        selected={remoteDestination === true}
                        onSelect={handleSelectRemoteDataAccessNode}
                        cardProps={{ sx: { height: "100%" } }}
                        icon={<MdOutlineCloud size={"6em"} />}
                        title={"Remote Data Access Node"}
                        description={"Migration to Remote Host via H2H Connections"}
                    />
                </Grid>
            </Grid>
            {
                remoteDestination &&
                <RemoteDataAccessNodeSelection projectId={projectId} onSelect={handleSelectRemoteDataAccessNode} selectedNodeId={wizardState.destinationNodeId} />
            }
            <StepperNavButtons
                nextButtonProps={{
                    disabled: !wizardState.destinationNodeId,
                }}
            />
        </Box>
    );
};

interface RemoteDataAccessNodeSelectionProps {
    projectId: string;
    onSelect: (dataAccessNodeId: string) => void;
    selectedNodeId: string;
}

const RemoteDataAccessNodeSelection: React.FC<RemoteDataAccessNodeSelectionProps> = (p) => {
    const { projectId, onSelect, selectedNodeId } = p;
    const wizardState = useCdmMigrationWizardState();
    const h2hConnections = useListGalaxyMigrateLinks(projectId, wizardState.sourceNodeId, true, 1, 100);

    return (
        <Box mb={2} width={"100%"}>
            <QueryResultWrapper queryResult={h2hConnections}>
                {
                    h2hConnections.data?.itemsList.length > 0 ?
                    <FormControl fullWidth>
                    <InputLabel variant={"filled"}>{`Remote Data Access Node`}</InputLabel>
                    <Select variant={"filled"} value={selectedNodeId} onChange={(e) => {
                        onSelect(e.target.value);
                    }}>
                        {h2hConnections.data?.itemsList.map((link) => (
                            <MenuItem key={link.linkid} value={link.client.systemId}>
                                {link.client.systemName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                :
                <Stack spacing={2}>
                    <Typography>
                        {`No remote data access nodes found.`}
                    </Typography>
                    <Box>
                       <CreateGalaxyMigrateLinkButton variant={"contained"} color={"primary"} label={"Create H2H Connection"} refetchFn={h2hConnections.refetch} />
                    </Box>
                    
                </Stack>
                }
                
            </QueryResultWrapper>
        </Box>
    );
};
