import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useCdmMigrationWizardState } from "./CdmMigrationWizardState";
import { useState } from "react";
import { CdmMigrationStorageSelectionTable, DataContainerSelectionTable } from "./CdmMigrationWizardSourceSelectionStep";
import { CdmStorageListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";

interface CdmMigrationWizardDestinationSelectionStepProps {
    projectId: string;
}

export const CdmMigrationWizardDestinationSelectionStep: React.FC<CdmMigrationWizardDestinationSelectionStepProps> = (p) => {
    const { projectId } = p;
    const wizardState = useCdmMigrationWizardState();
    const [destinationStorageId, setDestinationStorageId] = useState<string | null>(null);
    const [destinationDataContainerId, setDestinationDataContainerId] = useState<string | null>(null);
    const [destinationBasePath, setDestinationBasePath] = useState<string>(wizardState.sourceBasePath === "/" ? "" : wizardState.sourceBasePath);

    const handleSelectDestinationStorage = (storage: CdmStorageListItem.AsObject) => {
        setDestinationStorageId(storage.info?.id);
        setDestinationDataContainerId(null);
        wizardState.setDestinationStorageId(storage.info?.id);
    };

    const handleSelectDestinationDataContainer = (dataContainerId: string) => {
        setDestinationDataContainerId(dataContainerId);
        wizardState.setDestinationContainerId(dataContainerId);
    };

    const handleSetDestinationBasePath = (basePath: string) => {
        setDestinationBasePath(basePath);
        wizardState.setDestinationPath(basePath);
    };

    return <Box>
        <Typography color={"textSecondary"}>{"Select the destination storage system and data container for this migration."}</Typography>
        <Box pt={1}>
            <Typography variant={"h6"}>{"1. Select Destination Storage"}</Typography>
            <CdmMigrationStorageSelectionTable type={"Destination"}
                selectedStorageId={destinationStorageId} projectId={projectId}
                onSelect={handleSelectDestinationStorage} />
        </Box>
        {destinationStorageId && (
            <Box pt={1}>
                <Typography variant={"h6"}>{"2. Select Destination Data Container"}</Typography>
                <DataContainerSelectionTable projectId={projectId}
                selectedDataContainerId={destinationDataContainerId}
                onSelect={handleSelectDestinationDataContainer}
                selectedStorageId={destinationStorageId}
                    selectedDataAccessNodeId={wizardState.destinationNodeId} />
            </Box>
        )}
        {destinationDataContainerId && (
            <Box pt={1}>
                <Typography variant={"h6"}>{"3. Enter Destination Base Path"}</Typography>
                <TextField
                    label="Destination Base Path"
                    value={destinationBasePath}
                    slotProps={{
                        input: {
                            startAdornment: <InputAdornment position="start">/</InputAdornment>,
                        },
                    }}
                    onChange={(e) => handleSetDestinationBasePath(e.target.value)}
                />
            </Box>
        )}
        <Box pt={2}>
            <StepperNavButtons
                nextButtonProps={{
                    disabled: !destinationStorageId || !destinationDataContainerId,
                }}
            />
        </Box>
    </Box>

        ;
};