import { Box, InputLabel, MenuItem, Select, FormControl, Typography, Button, Stack, TextField, Card, ButtonGroup } from "@mui/material";
import { useCdmMigrationWizardState } from "./CdmMigrationWizardState";
import { QuickTipButton } from "../../../help/HelpCommon";
import { useState } from "react";
import Grid from '@mui/material/Grid2';
import { CdmReSyncSchedule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { SelectableBox } from "../../../../common/card/SelectableCard";
import { formatTitleCase } from "../../../../common/utils/formatter";
import { useIsDesktop } from "../../../layout/MainLayout";
import { DayOfWeek } from "gc-web-proto/galaxycompletepb/commonpb/common_pb";
import { OutlinedCard } from "../../../../common/card/OutlinedCard";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


interface CdmMigrationWizardMigrationConfigStepProps {
    projectId: string;
}

export const CdmMigrationWizardMigrationConfigStep: React.FC<CdmMigrationWizardMigrationConfigStepProps> = (p) => {
    const { projectId } = p;
    const wizardState = useCdmMigrationWizardState();
    const [timezone, setTimezone] = useState<string>("");
    const handleTimezoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTimezone(e.target.value);
        wizardState.setTimezone(e.target.value);
    };

    const [reSyncFrequency, setReSyncFrequency] = useState<CdmReSyncSchedule.ReSyncFrequency>(CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_UNSPECIFIED);
    const [reSyncDays, setReSyncDays] = useState<DayOfWeek[]>([]);
    const [reSyncTime, setReSyncTime] = useState<Date>(null);
    const [cronExpression, setCronExpression] = useState<string>("");
    const [excludeFilePatterns, setExcludeFilePatterns] = useState<string[]>([]);

    const handleSetReSyncFrequency = (frequency: CdmReSyncSchedule.ReSyncFrequency) => {
        setReSyncFrequency(frequency);
        wizardState.setReSyncFrequency(frequency);
        if (frequency === CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED) {
            setReSyncDays([])
            setReSyncTime(null)
            wizardState.setReSyncDays([]);
            wizardState.setReSyncTime(null);
        }
    };

    const handleCronExpressionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCronExpression(e.target.value);
        wizardState.setCronExpression(e.target.value);
    };



    return <Box>
        <Typography color={"textSecondary"}>{"Configure the migration settings."}</Typography>
        <Box pt={2}>
            <Typography variant={"h6"}>{"1. Re-sync Schedule"}</Typography>
            <Typography color={"textSecondary"}>
                {`Set how often you want the migration to re-sync data between the source and destination. 
            Choose a preferred interval or customize your schedule.`}
            </Typography>
            <Box pt={2}>
                <OutlinedCard sx={{ bgcolor: 'rgba(255, 255, 255, 0)' }}>
                    <Box p={2}>
                        <Typography fontWeight={"bold"}>{"Re-sync Frequency"}</Typography>
                        <Grid container spacing={2} pt={1}>
                            {Object.entries(CdmReSyncSchedule.ReSyncFrequency).filter(([key, value]) => key !== "RESYNC_FREQUENCY_UNSPECIFIED").map(([key, value]) => (
                                <Grid key={key}>
                                    <SelectableBox selected={reSyncFrequency === value} onSelect={() => handleSetReSyncFrequency(value as CdmReSyncSchedule.ReSyncFrequency)}>
                                        <Box p={1} pr={4} pl={4}>
                                            <Typography>{formatTitleCase(key.split("_")[2])}</Typography>
                                        </Box>
                                    </SelectableBox>
                                </Grid>
                            ))}
                        </Grid>
                        {
                            ![CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED, CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_UNSPECIFIED].includes(reSyncFrequency) && (
                                <>
                                    <Box pt={2}>
                                        <Typography fontWeight={"bold"}>{"Run on these days:"}</Typography>
                                        <Grid container spacing={2} pt={1}>
                                            {Object.entries(DayOfWeek).filter(([key, value]) => key !== "DAY_OF_WEEK_UNSPECIFIED").map(([key, value]) => (
                                                <Grid key={key}>
                                                    <SelectableBox selected={reSyncDays.includes(value as DayOfWeek)}
                                                        onSelect={() => {
                                                            setReSyncDays([...reSyncDays, value as DayOfWeek])
                                                            wizardState.setReSyncDays([...reSyncDays, value as DayOfWeek])
                                                        }}
                                                        onDeselect={() => {
                                                            setReSyncDays(reSyncDays.filter(day => day !== value as DayOfWeek))
                                                            wizardState.setReSyncDays(reSyncDays.filter(day => day !== value as DayOfWeek))
                                                        }}>
                                                        <Box p={1} pr={4} pl={4}>
                                                            <Typography>{formatTitleCase(key).slice(0, 3)}</Typography>
                                                        </Box>
                                                    </SelectableBox>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                    <Stack spacing={2} pt={2}>
                                        <Typography fontWeight={"bold"}>{"Starting at this time:"}</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                value={reSyncTime}
                                                onChange={(v) => {
                                                    setReSyncTime(v)
                                                    wizardState.setReSyncTime(v)
                                                }}
                                            />
                                        </LocalizationProvider>

                                    </Stack>
                                    <Stack spacing={2} pt={2}>
                                        <Typography fontWeight={"bold"}>{"Summary"}</Typography>
                                        <Typography>
                                            {`The migration will run ${formatTitleCase(Object.keys(CdmReSyncSchedule.ReSyncFrequency).find(key => CdmReSyncSchedule.ReSyncFrequency[key as keyof typeof CdmReSyncSchedule.ReSyncFrequency] === reSyncFrequency) || "").split("_")[2]}${reSyncDays.length > 0 ? ` on ${Object.keys(DayOfWeek).filter(key => reSyncDays.includes(DayOfWeek[key as keyof typeof DayOfWeek])).map(key => formatTitleCase(`${key}s`)).join(", ")}` : ""}${reSyncTime ? ` starting on ${reSyncTime.toLocaleDateString()} at ${reSyncTime.toLocaleTimeString()}` : ""}.`}
                                        </Typography>
                                    </Stack>
                                </>
                            )
                        }
                        {
                            reSyncFrequency === CdmReSyncSchedule.ReSyncFrequency.RESYNC_FREQUENCY_ADVANCED && (
                                <Stack spacing={2} pt={2}>
                                    <Typography fontWeight={"bold"}>{"Cron Expression:"}</Typography>
                                        <TextField
                                            label={"Cron Expression"}
                                            value={cronExpression}
                                            onChange={handleCronExpressionChange}
                                            helperText={"Enter a valid cron expression to customize the re-sync schedule."}
                                        />
                                        {/* <Typography color={'textSecondary'}>{"Example: 0 0 "}</Typography> */}
                                </Stack>
                            )
                        }
                    </Box>
                </OutlinedCard>
            </Box>
        </Box>
        <Stack spacing={2} pt={2}>
            <Typography variant={"h6"}>{"2. Timezone"}</Typography>
            <TextField
                label={"Timezone"}
                value={timezone}
                onChange={handleTimezoneChange}
            />
            {/* <FormControl>
                <InputLabel variant={"filled"}>Timezone</InputLabel>
                <Select variant={"filled"}>
                    <MenuItem value={"UTC"}>UTC</MenuItem>
                </Select>
            </FormControl> */}
        </Stack>
        <Box pt={2}>
            <Typography variant={"h6"}>{"3. QoS Configuration"}</Typography>
        </Box>
        <Box pt={2}>
            <Stack direction={"row"} spacing={2}>
                <Typography variant={"h6"}>{"4. Exclude File Patterns"}</Typography>
                <QuickTipButton
                    title={"Exclude File Patterns"}
                />
            </Stack>
        </Box>
    </Box>
};