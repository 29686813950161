import { DynamicStepper } from "../../../../common/stepper/StepperComponents";
import { StepConfig } from "../../../../common/stepper/StepperHelpers";
import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import { CdmMigrationWizardDestinationConnectivityStep } from "./CdmMigrationWizardDestinationConnectivityStep";
import { CdmMigrationWizardDestinationSelectionStep } from "./CdmMigrationWizardDestinationSelectionStep";
import { CdmMigrationWizardMigrationConfigStep } from "./CdmMigrationWizardMigrationConfigStep";
import { CdmMigrationWizardSourceSelectionStep } from "./CdmMigrationWizardSourceSelectionStep";

interface CdmMigrationWizardProps {
    projectId: string;
}

export const CdmMigrationWizard: React.FC<CdmMigrationWizardProps> = (p) => {
    const { projectId } = p;
    const stepConfigs: StepConfig[] = [
        {
            id: "source",
            label: "Source Selection",
            renderer: () => <CdmMigrationWizardSourceSelectionStep projectId={projectId} />,
        },
        {
            id: "destination-connectivity",
            label: "Destination Connectivity",
            renderer: () => <CdmMigrationWizardDestinationConnectivityStep projectId={projectId} />,
        },
        {
            id: "destination-selection",
            label: "Destination Selection",
            renderer: () => <CdmMigrationWizardDestinationSelectionStep projectId={projectId} />,
        },
        {
            id: "migration-configuration",
            label: "Migration Configuration",
            renderer: () => <CdmMigrationWizardMigrationConfigStep projectId={projectId} />,
        },
    ];

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"New CDM Migration"} />
            <DynamicStepper
                stepperProps={{
                    activeStep: 3,
                    stepConfigs: stepConfigs,
                    startingStep: 0,
                    totalSteps: stepConfigs.length,
                }}
                orientation={"horizontal"}
            />
        </ScreenContainer>
    );
};
