// ======================
// CdmMigrationListScreen
// ======================

import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import { Button, Link } from "@mui/material";
import { useListCdmMigrations } from "../unstructured_data_hooks";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { OperatorView } from "../../auth/AuthenticatedViews";
import { CdmQueryKeys } from "../../../common/QueryKeys";
import { QueryTable } from "../../../common/table/QueryTable";
import { CdmMigrationInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { CdmSynchronizationStatus } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_synchronization_status_pb";
import { useNavigateToCdmMigrationDetailsScreen, useNavigateToCdmMigrationWizard } from "../UnstructuredDataCommon";

interface CdmMigrationListScreenProps {
    projectId: string;
}

export const CdmMigrationListScreen: React.FC<CdmMigrationListScreenProps> = (p) => {
    const { projectId } = p;
    const goToCdmMigrationWizard = useNavigateToCdmMigrationWizard();
    return (
        <ScreenContainer>
            <ScreenTitleBar
                title={"Migrations"}
                actions={
                    <OperatorView>
                        <Button onClick={goToCdmMigrationWizard} variant={"contained"} color={"secondary"}>
                            {`Create New Migration`}
                        </Button>
                    </OperatorView>
                }
            />
            <CdmMigrationListTable projectId={projectId} />
        </ScreenContainer>
    );
};

interface CdmMigrationListTableProps {
    projectId: string;
    status?: CdmSynchronizationStatus;
    nodeIdList?: string[];
    passesPerMigration?: number;
}

export const CdmMigrationListTable: React.FC<CdmMigrationListTableProps> = (p) => {
    const { projectId, status, nodeIdList, passesPerMigration } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 30 });

    const cdmMigrationsList = useListCdmMigrations(projectId, pageIndex, pageSize, status, nodeIdList, passesPerMigration);

    const columnHelper = createColumnHelper<CdmMigrationInfo.AsObject>();

    const goToCdmMigrationDetails = useNavigateToCdmMigrationDetailsScreen();

    const columns = [
        columnHelper.accessor((r) => r.migration?.id, {
            header: "Migration",
            cell: (props) => {
                return <Link onClick={() => goToCdmMigrationDetails(props.getValue())}>{props.getValue()}</Link>;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "Source > Destination",
            cell: (props) => {
                return (
                    <div>
                        {props.row.original.migration?.sourceStorage?.name} {">"} {props.row.original.migration?.destinationStorage?.name}
                    </div>
                );
            },
        }),
    ];

    return (
        <QueryTable
            data={cdmMigrationsList.data?.itemsList}
            columns={columns}
            pagination={{ pageIndex, pageSize }}
            setPagination={setPagination}
            pageCount={cdmMigrationsList.data?.pagerMeta.totalPages}
            queryKey={CdmQueryKeys.listCdmMigrations}
            emptyTableConfig={{
                title: "No migrations found",
                message: "No migrations found for the project",
            }}
        />
    );
};
