import { useGrpcApiStore } from "../grpc/grpcApiStore";
import { ConfigureVmwareHelper, GetGMStorageConfig, ListGalaxyMigrateLinks } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/galaxymigrate_api_pb";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DeploymentQueryKeys, GalaxyMigrateMutationKeys, GmDeploymentQueryKeys } from "../../common/QueryKeys";
import { PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { useAppServices } from "../app/services";

export const useListGalaxyMigrateLinks = (projectId: string, systemIdFilter?: string, onlyConnected?: boolean, page?: number, perPage?: number) => {
    const apis = useGrpcApiStore();
    const queryFn = async () => {
        const req = new ListGalaxyMigrateLinks.Request().setProjectId(projectId).setPageParams(new PagerParams().setPage(page || 0).setPerPage(perPage || 30));

        if (!!systemIdFilter) {
            req.setSystemIdFilter(systemIdFilter);
        }
        if (!!onlyConnected) {
            req.setOnlyConnected(onlyConnected);
        }
        const res = await apis.gmService.listGalaxyMigrateLinks(req, null);
        return res.toObject();
    };
    return useQuery({
        queryKey: [DeploymentQueryKeys.listGmLinks, projectId, systemIdFilter, onlyConnected],
        queryFn: queryFn,
        refetchInterval: 10 * 1000,
    });
};

export const useConfigureVmwareHelper = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();
    const mutationFn = async (req: ConfigureVmwareHelper.Request) => {
        return await apis.gmService.configureVmwareHelper(req, null);
    };

    return useMutationTrackAndAlertError({
        mutationKey: [GalaxyMigrateMutationKeys.configureVmwareHelper],
        mutationFn: mutationFn,
        onSuccess: async () => {
            await queryClient.refetchQueries({
                queryKey: [DeploymentQueryKeys.listGmDeployments],
            });
        },
    });
};

export const useConfigureAzureHelper = () => {
    const { gmDeploymentService } = useAppServices();
    const mutationFn = async (args: { systemId: string; integrationId: number }) =>
        await gmDeploymentService.configureAzureHelper(args.systemId, args.integrationId);
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [`configureAzureHelper`],
        mutationFn: mutationFn,
    });
};

export const useGetDeploymentStorageConfig = (deploymentId?: string, includeLvm?: boolean) => {
    const apis = useGrpcApiStore();
    const req = new GetGMStorageConfig.Request().setSystemId(deploymentId).setIncludeLvm(includeLvm || false);
    const queryFn = async () => {
        const res = await apis.gmService.getStorageConfig(req, null);
        return res.getStorageConfig().toObject();
    };
    return useQuery({
        queryKey: [GmDeploymentQueryKeys.getDeploymentStorageConfig],
        queryFn: queryFn,
    });
};
