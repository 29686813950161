import { Box, Radio, Typography, FormControl, MenuItem, Select, SelectChangeEvent, TextField, InputAdornment, InputLabel } from "@mui/material";
import { useListCdmStorages, useListCdmDataAccessNodes, useListCdmStorageDataContainers } from "../../unstructured_data_hooks";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import { useState } from "react";
import { QueryTable } from "../../../../common/table/QueryTable";
import { CdmDataContainerBasicInfo, CdmStorageConnection, CdmStorageListItem } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { useCdmMigrationWizardState } from "./CdmMigrationWizardState";
import { useList } from "react-use";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import { CdmDataContainerStatusChip, getDataContainerTypeLabel } from "../../UnstructuredDataCommon";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";

interface CdmMigrationWizardSourceSelectionStepProps {
    projectId: string;
}

export const CdmMigrationWizardSourceSelectionStep: React.FC<CdmMigrationWizardSourceSelectionStepProps> = (p) => {
    const { projectId } = p;
    const wizardState = useCdmMigrationWizardState();

    const [selectedStorage, setSelectedStorage] = useState<CdmStorageListItem.AsObject | null>(null);
    const [dataAccessNodeId, setDataAccessNodeId] = useState<string | null>(null);
    const [dataContainerId, setDataContainerId] = useState<string | null>(null);
    const [basePath, setBasePath] = useState<string | null>(null);

    const handleSelectSourceStorage = (storage: CdmStorageListItem.AsObject) => {
        setSelectedStorage(storage);
        setDataAccessNodeId(null); // Reset downstream selections
        setDataContainerId(null);
        wizardState.setSourceStorageId(storage.info?.id);
    };

    const handleSelectDataAccessNode = (nodeId: string) => {
        setDataAccessNodeId(nodeId);
        setDataContainerId(null); // Reset downstream selection
        wizardState.setSourceNodeId(nodeId);
    };

    const handleSelectDataContainer = (dataContainerId: string) => {
        setDataContainerId(dataContainerId);
        wizardState.setSourceContainerId(dataContainerId);
    };

    const handleSetBasePath = (basePath: string) => {
        setBasePath(`${basePath}`);
        wizardState.setSourceBasePath(`${basePath}`);
    };

    return (
        <Box>
            <Typography color={"textSecondary"}>{"Select the storage system and data container you want to migrate data from."}</Typography>
            <Box pt={2}>
                <Typography variant={"h6"}>{"1. Select Source Storage"}</Typography>
                <CdmMigrationStorageSelectionTable type={"Source"} selectedStorageId={selectedStorage?.info?.id} projectId={projectId} onSelect={handleSelectSourceStorage} />
            </Box>
            {selectedStorage && (
                <Box pt={2}>
                    <Typography variant={"h6"}>{"2. Select Data Access Node"}</Typography>
                    <Box pt={1}>
                        <DataAccessNodeSelection
                            projectId={projectId}
                            selectedNodeId={dataAccessNodeId}
                            storage={selectedStorage}
                            onSelect={handleSelectDataAccessNode}
                        />
                    </Box>
                </Box>
            )}
            {dataAccessNodeId && (
                <Box pt={2}>
                    <Typography variant={"h6"}>{"3. Select Data Container"}</Typography>
                    <Box pt={1}>
                        <DataContainerSelectionTable
                            projectId={projectId}
                            selectedDataContainerId={dataContainerId}
                            onSelect={handleSelectDataContainer}
                            selectedStorageId={selectedStorage?.info?.id}
                            selectedDataAccessNodeId={dataAccessNodeId}
                        />
                    </Box>
                </Box>
            )}
            {dataContainerId && (
                <Box pt={2}>
                    <Typography variant={"h6"}>{"4. Enter Base Path"}</Typography>
                    <Box pt={1}>
                        <TextField
                            variant={"filled"}
                            slotProps={{
                                input: {
                                    startAdornment: <InputAdornment position="start">/</InputAdornment>,
                                },
                            }}
                            label={"Base Path"}
                            value={basePath}
                            onChange={(e) => handleSetBasePath(e.target.value)}
                        />
                    </Box>
                </Box>
            )}
            <Box pt={2}>
                <StepperNavButtons
                    nextButtonProps={{
                        disabled: !selectedStorage || !dataAccessNodeId || !dataContainerId,
                    }}
                />
            </Box>

        </Box>
    );
};

interface CdmMigrationStorageSelectionTableProps {
    projectId: string;
    onSelect: (storage: CdmStorageListItem.AsObject) => void;
    selectedStorageId: string;
    type: "Source" | "Destination";
}

export const CdmMigrationStorageSelectionTable: React.FC<CdmMigrationStorageSelectionTableProps> = (p) => {
    const { projectId, onSelect, selectedStorageId, type } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 30 });
    const cdmStorageList = useListCdmStorages(projectId, pageIndex, pageSize);

    const columnHelper = createColumnHelper<CdmStorageListItem.AsObject>();

    const columns = [
        columnHelper.display({
            id: "select",
            header: "",
            cell: (props) => {
                return <Radio checked={props.row.original.info?.id === selectedStorageId} onClick={() => onSelect(props.row.original)} />;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: `${type} Storage`,
            cell: (props) => {
                return <div>{props.row.original.info.name}</div>;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "NFS Connections",
            cell: (props) => {
                return <div>{props.row.original.connectionsList.filter((c) => c.protocol === CdmStorageConnection.Protocol.PROTOCOL_NFS).length}</div>;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "SMB Connections",
            cell: (props) => {
                return <div>{props.row.original.connectionsList.filter((c) => c.protocol === CdmStorageConnection.Protocol.PROTOCOL_SMB).length}</div>;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "S3 Connections",
            cell: (props) => {
                return <div>{props.row.original.connectionsList.filter((c) => c.protocol === CdmStorageConnection.Protocol.PROTOCOL_S3).length}</div>;
            },
        }),
    ];

    return (
        <QueryTable
            columns={columns}
            data={cdmStorageList.data?.itemsList || []}
            pagination={{ pageIndex, pageSize }}
            setPagination={setPagination}
            emptyTableConfig={{
                title: "No storage systems found",
                message: "No storage systems found in the project.",
            }}
        />
    );
};

interface DataAccessNodeSelectionProps {
    storage: CdmStorageListItem.AsObject;
    onSelect: (dataAccessNodeId: string) => void;
    projectId: string;
    selectedNodeId: string;
}

const DataAccessNodeSelection: React.FC<DataAccessNodeSelectionProps> = (p) => {
    const { storage, onSelect, projectId, selectedNodeId } = p;

    // Get all node IDs from storage connections
    const nodeIds = storage.connectionsList.map((conn) => conn.nodeId);

    // Fetch data access nodes
    const dataAccessNodes = useListCdmDataAccessNodes(projectId, 1, 1000);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const nodeId = event.target.value;
        onSelect(nodeId);
    };

    // Filter nodes to only show ones associated with the storage connections
    const filteredNodes = dataAccessNodes?.data?.itemsList.filter((node) => nodeIds.includes(node.deployment?.systemId)) || [];

    return (
        <QueryResultWrapper queryResult={dataAccessNodes}>
            <FormControl fullWidth>
                <InputLabel variant={"filled"}>{`Data Access Node`}</InputLabel>
                <Select variant={"filled"} value={selectedNodeId} onChange={handleChange} displayEmpty>
                    {filteredNodes.map((node) => (
                        <MenuItem key={node.deployment?.systemId} value={node.deployment?.systemId}>
                            {node?.deployment?.systemName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </QueryResultWrapper>
    );
};

interface DataContainerSelectionTableProps {
    projectId: string;
    selectedDataContainerId: string;
    onSelect: (dataContainerId: string) => void;
    selectedStorageId: string;
    selectedDataAccessNodeId: string;
}

export const DataContainerSelectionTable: React.FC<DataContainerSelectionTableProps> = (p) => {
    const { projectId, selectedDataContainerId, onSelect, selectedStorageId, selectedDataAccessNodeId } = p;
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 30 });

    const dataContainers = useListCdmStorageDataContainers({
        projectId,
        storageId: selectedStorageId,
        nodeId: selectedDataAccessNodeId,
        pageIndex,
        perPage: pageSize,
    });

    const columnHelper = createColumnHelper<CdmDataContainerBasicInfo.AsObject>();

    const columns = [
        columnHelper.display({
            id: "select",
            header: "",
            cell: (props) => {
                return <Radio checked={props.row.original.id === selectedDataContainerId} onClick={() => onSelect(props.row.original.id)} />;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "Data Container",
            cell: (props) => {
                return <div>{props.row.original.name}</div>;
            },
        }),
        columnHelper.accessor((r) => r, {
            header: "Data Container Type",
            cell: (props) => {
                return <div>{getDataContainerTypeLabel(props.row.original.type)}</div>;
            },
        }),
        columnHelper.accessor((r) => r.path, {
            id: "path",
            header: "Path",
        }),
        columnHelper.accessor((r) => r.sizeBytes, {
            id: "sizeBytes",
            header: "Size",
            cell: (props) => {
                return <Typography>{formatKnownDataType(props.getValue(), KnownDataType.CAPACITY)}</Typography>;
            },
        }),
        columnHelper.accessor((r) => r.status, {
            id: "status",
            header: "Status",
            cell: (props) => {
                return <CdmDataContainerStatusChip status={props.getValue()} />;
            },
        }),
    ];

    return <QueryTable columns={columns} data={dataContainers.data?.itemsList || []} pagination={{ pageIndex, pageSize }} setPagination={setPagination} />;
};
