// ======================
// ProjectUnstructuredDataScreens
// ======================

import { Navigate, Route, Routes } from "react-router-dom";
import { UNSTRUCTURED_DATA_SUBROUTE } from "../app/AppRoutes";
import { ConnectedStorageListScreen } from "./connectedStorage/ConnectedStorageListScreen";
import { ConnectNewStorageWizard } from "./connectedStorage/wizard/ConnectNewStorageWizard";
import { DataAccessNodeListScreen } from "./dataAccessNode/DataAccessNodeListScreen";
import { CdmMigrationListScreen } from "./migration/CdmMigrationListScreen";
import { ConnectedStorageDetailsScreen } from "./connectedStorage/ConnectedStorageDetailsScreen";
import { CdmMigrationWizard } from "./migration/wizard/CdmMigrationWizard";

interface ProjectUnstructuredDataScreensProps {
    projectId: string;
}

export const ProjectUnstructuredDataScreens: React.FC<ProjectUnstructuredDataScreensProps> = (p) => {
    const { projectId } = p;
    return (
        <Routes>
            <Route index element={<Navigate to={UNSTRUCTURED_DATA_SUBROUTE.DATA_ACCESS_NODES} replace />} />
            <Route path={UNSTRUCTURED_DATA_SUBROUTE.DATA_ACCESS_NODES}>
                <Route index element={<DataAccessNodeListScreen projectId={projectId} />} />
            </Route>
            <Route path={UNSTRUCTURED_DATA_SUBROUTE.CONNECTED_STORAGE}>
                <Route index element={<ConnectedStorageListScreen projectId={projectId} />} />
                <Route path={":storageId"}>
                    <Route index element={<ConnectedStorageDetailsScreen projectId={projectId} />} />
                </Route>
                <Route path={UNSTRUCTURED_DATA_SUBROUTE.CONNECT_NEW_STORAGE} element={<ConnectNewStorageWizard />} />
            </Route>
            <Route path={UNSTRUCTURED_DATA_SUBROUTE.MIGRATIONS}>
                <Route index element={<CdmMigrationListScreen projectId={projectId} />} />
                <Route path={":migrationId"} element={<>Hello World</>} />
                <Route path={UNSTRUCTURED_DATA_SUBROUTE.NEW_MIGRATION} element={<CdmMigrationWizard projectId={projectId} />} />
            </Route>
        </Routes>
    );
};
